<template>
    <div class="wrapper-content-page">
        <div class="block-info">
            <div class="block-info__logo">
                <img
                    :src="require('@/assets/images/main/home-page/Logo.png')"
                    data-aos="fade-down"
                    @click="$router.push('/')"
                />
            </div>

            <h3 class="block-info__title" data-aos="fade-right">
                Nine Tales Privacy Policy
            </h3>

            <div class="block-info__content">
                <p class="desc-text desc-text--note" data-aos="fade">
                    Last updated: 20 September 2021
                </p>

                <p class="desc-text">
                    Please read through this privacy policy (“Privacy Policy”)
                    carefully as it explains to you how NineTales Pte. Ltd.
                    (UEN: 201925158Z), a company duly incorporated in Singapore
                    (“we”, “us” or “our”), collects, uses, discloses, retains
                    and otherwise processes Personal Data in accordance with the
                    Personal Data Protection Act (“PDPA”).
                </p>

                <p class="desc-text">
                    This Privacy Policy applies in conjunction with, and should
                    be read together with, the Terms of Service. Unless
                    otherwise defined, capitalised terms used in this Privacy
                    Policy have the same meanings given to them in the Terms of
                    Service.
                </p>

                <p class="title-text" data-aos="fade-right">1. GENERAL</p>

                <p class="desc-text">
                    “Personal Data” means data, whether true or not, about a
                    person who can be identified: (a) from that data; or (b)
                    from that data and other information to which we have or are
                    likely to have access. However, Personal Data does not
                    include information that has been made anonymous or
                    aggregated so that it can no longer be used to identify a
                    specific person, whether in combination with other
                    information or otherwise.
                </p>

                <p class="desc-text">
                    By accessing, visiting, browsing and/or using the Service,
                    you consent to us collecting, using, disclosing, storing and
                    processing your Personal Data as described in this Privacy
                    Policy. If you do not agree with any of its terms, you
                    should immediately discontinue use of the Website and/or
                    Service.
                </p>

                <p class="desc-text">
                    This Privacy Policy supplements but does not supersede nor
                    replace any other consent which you may have previously
                    provided to us, nor does it affect any rights which we may
                    have at law in connection with the collection, use or
                    disclosure of your Personal Data. We may revise this Privacy
                    Policy at any time in our sole discretion by posting the
                    revised version(s) on our Website. You agree to be bound by
                    all such changes to the Privacy Policy.
                </p>

                <p class="desc-text">
                    If you disagree with this Privacy Policy, or any changes to
                    it, you must stop using the Service. It is your
                    responsibility to check this Privacy Policy periodically
                    when you use the Service. Your continued use of the Service
                    after changes to the Privacy Policy have been made will
                    constitute your acceptance of the revised Privacy Policy.
                </p>

                <p class="title-text" data-aos="fade-right">
                    2. CONTACT INFORMATION
                </p>

                <p class="desc-text">
                    If you have any queries or requests relating to this Privacy
                    Policy or your Personal Data, please contact our Data
                    Protection Officer using the following contact details:
                </p>

                <div class="desc-text">
                    <p>NineTales Pte. Ltd.</p>
                    <p>284 River Valley Road</p>
                    <p>#01-01 Singapore 238325</p>
                    <p>Email: dpo@9tales.com</p>
                </div>

                <p class="title-text" data-aos="fade-right">
                    3. PERSONAL DATA WE COLLECT
                </p>

                <div class="desc-text">
                    <p>
                        We generally do not collect your Personal Data unless:
                    </p>
                    <p>
                        (a) it is provided to us voluntarily by you directly or
                        via a third party who has been duly authorised by you to
                        disclose your Personal Data to us (your “authorised
                        representative”), you (or your authorised
                        representative) have been notified of the purposes for
                        which it is collected and you (or your authorised
                        representative) have provided your consent to its
                        collection and usage for such purposes (by virtue of
                        this Privacy Policy or a separate consent process);
                    </p>
                    <p>
                        (b) it is provided to us voluntarily by you for a
                        purpose, and it is reasonable in the circumstances that
                        you are deemed to have consented to our collection, use
                        and disclosure of that Personal Data for that purpose;
                        or
                    </p>
                    <p>
                        (c) collection and use of Personal Data without consent
                        is permitted or required by applicable laws.
                    </p>
                </div>

                <div class="desc-text">
                    <p>We may collect your Personal Data whenever you:</p>
                    <p>(a) access and/or use the Website and/or App;</p>
                    <p>(b) register for an Account with us;</p>
                    <p>
                        (c) submit a form or enquiry through the Website and/or
                        App;
                    </p>
                    <p>(d) update or add information to your Account;</p>
                    <p>
                        (e) correspond with us, including by interacting with us
                        or requesting that we contact you;
                    </p>
                    <p>
                        (f) consent to be included in our email and/or other
                        mailing list; and/or
                    </p>
                    <p>
                        (g) provide us information, including in response to a
                        request by us, by submitting forms relating to the
                        Service and for any other reason.
                    </p>
                </div>

                <p class="desc-text">
                    If you register for a Storyteller Account with us, we may
                    collect your name, email address, and password, and phone
                    number.
                </p>

                <p class="desc-text">
                    If you register your child/ward for a Reader Account with
                    us, we may collect your/your child’s nickname, reading
                    preferences and date of birth.
                </p>

                <p class="desc-text">
                    Minors. We protect the Personal Data of your child/ward
                    under your Reader Account(s), by collecting only minimal
                    mandatory information necessary for you as the parent user
                    of the Storyteller Account to identify them easily via the
                    App and to receive a customized user experience. If you are
                    the parent or legal guardian of a child who is using the
                    Service and your child’s Account was not set up by you or if
                    you did not give consent to the same, you can submit a
                    request via email to our Data Protection Officer to have
                    that child’s account removed.
                </p>

                <p class="title-text" data-aos="fade-right">
                    4. USE AND DISCLOSURE OF PERSONAL DATA
                </p>

                <div class="desc-text">
                    <p>
                        (a) performing obligations in the course of or in
                        connection with our provision of the Service (and other
                        goods or services requested by you);
                    </p>
                    <p>
                        (b) providing you with personalised services and/or to
                        customise your user experience of the App;
                    </p>
                    <p>(c) verifying your identity;</p>
                    <p>
                        (d) responding to, handling, and processing queries,
                        requests, applications, complaints, feedback and
                        Submissions from you;
                    </p>
                    <p>
                        (e) generally administering and/or managing your
                        relationship with us;
                    </p>
                    <p>
                        (f) sending you information on any updates or changes to
                        the Service (including notifying you of any planned or
                        unscheduled downtime);
                    </p>
                    <p>
                        (g) sending you information on any updates or changes to
                        our Terms, Policies and/or other legal or
                    </p>
                    <p>
                        (h) sending you our newsletters, or advertising,
                        marketing and promotional communications, where you have
                        requested or consented to be on our mailing list or to
                        receive such communications;
                    </p>
                    <p>
                        (i) any purpose for which you have provided your
                        Personal Data;
                    </p>
                    <p>
                        (j) any other incidental business and/or administrative
                        purposes related to or in connection with the above;
                        and/or
                    </p>
                    <p>(k) such as we reasonably believe to be necessary to:</p>
                    <p>
                        (i) comply with applicable laws, regulations, codes of
                        practice, guidelines and/or rules or to assist in law
                        enforcement and investigations conducted by any
                        governmental and/or regulatory authority;
                    </p>
                    <p>
                        (ii) protect the rights, privacy, safety and/or property
                        of any person;
                    </p>
                    <p>
                        (iii) protect the safety or integrity of the Service
                        (including to help prevent spam, abuse, or malicious
                        actors on the Service);
                    </p>
                    <p>
                        (iv) prevent, detect, mitigate and investigate
                        potentially illegal acts, fraud and/or security breaches
                        and to assess and manage risk, including to alert you if
                        fraudulent activities have been detected on your
                        Account(s);
                    </p>
                    <p>(v) enforce the Terms; and/or</p>
                    <p>
                        (vi) allow us to pursue available remedies or limit the
                        damages we may sustain.
                    </p>
                </div>

                <div class="desc-text">
                    <p>
                        <b>Disclosure of Personal Data</b>. We may disclose your
                        Personal Data:
                    </p>
                    <p>
                        (a) where such disclosure is required for performing
                        obligations in the course of or in connection with our
                        provision of the Service (or other goods and services
                        requested by you); or
                    </p>
                    <p>
                        (b) to any unaffiliated third parties including our
                        third party service providers, agents and other
                        organisations we have engaged, and relevant governmental
                        and/or regulatory authorities, whether in Singapore or
                        abroad, for the aforementioned purposes.
                    </p>
                </div>

                <p class="desc-text">
                    If you provide Personal Data of third parties to us. If you
                    provide us with the Personal Data of a spouse, child,
                    parent, authorised representative or other third party
                    individual (e.g. by sending emails or email invitations to
                    others through the App), you represent and warrant that you
                    have obtained the consent of such third party individual to
                    the collection, use and disclosure of their Personal Data
                    under this Privacy Policy.
                </p>

                <p class="title-text" data-aos="fade-right">
                    5. WITHDRAWING YOUR CONSENT
                </p>

                <p class="desc-text">
                    The consent that you provide for the collection, use,
                    disclosure, retention and processing of your Personal Data
                    will remain valid until such time as it is withdrawn by you
                    in writing. You may withdraw your consent and request for us
                    to stop collecting, using, disclosing, retaining and/or
                    processing your Personal Data for any or all of the purposes
                    listed above by submitting your request via email to our
                    Data Protection Officer.
                </p>

                <p class="desc-text">
                    <b>Response time</b>. Upon receipt of your written request
                    to withdraw your consent, we may require reasonable time
                    (depending on the complexity of your request and its impact
                    on our relationship with you) for your request to be
                    processed and for us to notify you of the consequences of us
                    acceding to the same, including any legal consequences which
                    may affect your rights and liabilities to us.
                </p>

                <p class="desc-text">
                    In general, we shall seek to process your request within
                    fourteen (14) days of receiving it. You acknowledge that we
                    will not be able to remove your Personal Data from the
                    database of any other users with whom you have shared such
                    information or from the databases of third parties which you
                    previously authorised us to share such information with.
                </p>

                <p class="desc-text">
                    <b> Cessation of service</b>. Whilst we respect your
                    decision to withdraw your consent, please note that
                    depending on the nature and scope of your request, we may
                    not be in a position to continue providing the Service to
                    you and may also need to terminate your Account(s) with us.
                    We shall, in such circumstances, notify you before
                    completing the processing of your request. Should you decide
                    to cancel your withdrawal of consent, please inform us in
                    writing via email to our Data Protection Officer within 14
                    days of your request for withdrawal of consent, failing
                    which we shall proceed as you initially instructed.
                </p>

                <p class="desc-text">
                    Please note that withdrawing consent does not affect our
                    right to continue to collect, use, disclose, retain and/or
                    process Personal Data where such collection, use,
                    disclosure, retention and/or processing is permitted or
                    required under applicable laws.
                </p>

                <p class="desc-text">
                    <b>Unsubscribe</b>. If you do not wish to receive e-mail,
                    messages or other communications from us, you can
                    unsubscribe from such communications by using the
                    unsubscribe facility that may be provided with such e-mail,
                    message or communication, or submit a written request via
                    email to our Data Protection Officer. Please note that if
                    you do opt out of receiving marketing-related communications
                    from us, we may still send you important administrative
                    messages, and that you cannot opt out of receiving such
                    administrative or other important messages.
                </p>

                <p class="title-text" data-aos="fade-right">
                    6. ACCESSING AND CORRECTING YOUR PERSONAL DATA
                </p>

                <p class="desc-text">
                    <b> Accuracy of Personal Data</b>. We generally rely on
                    Personal Data provided by you, or your authorised
                    representative.
                </p>

                <p class="desc-text">
                    <b>Updating/correcting Personal Data</b>. In order to ensure
                    that your Personal Data is current, complete and accurate,
                    please update us if there are changes to your Personal Data
                    by updating your Personal Data in your Account(s) (if
                    applicable) or submitting your request via email to our Data
                    Protection Officer.
                </p>
                <p class="desc-text">
                    <b>Access request</b>. To request access to your Personal
                    Data, please submit your request via email to our Data
                    Protection Officer. Please note that a reasonable fee may be
                    charged for an access request. If so, we will inform you of
                    the fee before processing your request.
                </p>
                <p class="desc-text">
                    <b>Response time</b>. We will respond to your request for
                    access to or correction of your Personal Data as soon as
                    reasonably possible. In general, our response will be within
                    fourteen (14) days. Should we not be able to respond to your
                    request within thirty (30) days after receiving your
                    request, we will inform you in writing within thirty (30)
                    days of the time by which we will be able to respond to your
                    request.
                </p>

                <p class="title-text" data-aos="fade-right">
                    7. PROTECTION OF PERSONAL DATA
                </p>

                <p class="desc-text">
                    To safeguard your Personal Data from unauthorised access,
                    collection, use, disclosure, copying, modification, disposal
                    or similar risks, we have introduced commercially reasonable
                    physical, administrative, technical and electronic measures
                    and safeguards such as minimised collection of Personal
                    Data, authentication and access controls (such
                    as\need-to-basis for data disclosure, etc), encryption of
                    data, regular patching of operating system and other
                    software, securely erase storage media in devices before
                    disposal.
                </p>

                <p class="desc-text">
                    While we strive to protect the security of your Personal
                    Data and are constantly reviewing and enhancing our
                    information security measures, you should be aware that no
                    security measures that we take to protect your information
                    is impenetrable or absolutely guaranteed to avoid
                    unauthorised access or use of your Personal Data. Further,
                    no method of transmission over the Internet or method of
                    electronic storage is completely secure.
                </p>

                <p class="desc-text">
                    You are solely responsible for safeguarding the usernames
                    and/or passwords of your Account(s) and keeping them
                    confidential. You acknowledge that if you lose control of
                    your usernames and/or passwords, you may lose substantial
                    control over your Personal Data, and you shall be solely and
                    fully liable for any disclosure or unauthorised use thereof.
                </p>

                <p class="title-text" data-aos="fade-right">
                    8. RETENTION OF PERSONAL DATA
                </p>

                <p class="desc-text">
                    We will retain your Personal Data (including Personal Data
                    from terminated Accounts) for as long as is necessary to
                    fulfil the purpose for which it is collected, or as required
                    or permitted by applicable laws or under this Privacy
                    Policy.
                </p>

                <p class="desc-text">
                    Please note that the purposes listed in this Policy for
                    which Personal Data may be collected, used and disclose, may
                    continue to apply even in situations where your relationship
                    with us has been terminated or altered in any way, for a
                    reasonable period thereafter (including, where applicable, a
                    period to enable us to enforce our rights under any contract
                    with you).
                </p>

                <p class="desc-text">
                    Typically, our legitimate business purposes do not require
                    us to retain your Personal Data for more than 180 days
                    following the termination of your Account. However, we may
                    retain your personal data for longer periods for legal
                    purposes, or if otherwise required or permitted by
                    applicable laws.
                </p>

                <div class="desc-text">
                    <p>
                        We will cease to retain your Personal data (or remove
                        the means by which such data can be associated with you)
                        where:
                    </p>

                    <p>
                        (a) we no longer need the Personal Data for the purposes
                        for which it was collected, or legal or business
                        purposes and we are not otherwise entitled to retain
                        such data; and
                    </p>

                    <p>
                        (b) we are not required by law, regulation or court
                        order to retain the information.
                    </p>
                </div>

                <p class="desc-text">
                    If Personal Data has been provided to third parties in
                    accordance with this Privacy Policy, retention of that
                    Personal Data will be subject to the policies of the
                    relevant third party.
                </p>

                <p class="title-text" data-aos="fade-right">
                    9. TRANSFERS OF PERSONAL DATA OUTSIDE OF SINGAPORE
                </p>

                <p class="desc-text">
                    In order to provide you with the use of the Service and
                    related services and functions (including without limitation
                    customer support, app maintenance, back office functions,
                    etc.) we may need to allow our staff or third party service
                    providers (who may be located or whose servers and resources
                    may be located in a location other than your country of
                    residence) access to your Personal Data. Such transfers of
                    your Personal Data are necessary for us to provide the
                    Service as intended.
                </p>

                <p class="desc-text">
                    To the extent that we may need to transfer your Personal
                    Data outside of Singapore, we shall do so in accordance with
                    the PDPA to ensure that we provide a standard of protection
                    to personal data so transferred that is comparable to the
                    protection under the PDPA, including by ensuring that the
                    recipient is either in a jurisdiction which has comparable
                    data protection laws, or is contractually bound to protect
                    your Personal Data.
                </p>

                <p class="desc-text">
                    By using our Service, and providing us with your Personal
                    Data, you are explicitly consenting to the international
                    transfer and processing of such data in accordance with this
                    Privacy Policy, in full and informed knowledge of the risks
                    associated with such transfers and processing.
                </p>

                <div class="desc-text">
                    <p>
                        Third party service providers to whom your Personal Data
                        may be transferred overseas include the following:
                    </p>
                    <p>
                        Microsoft (Analytics, Diagnostics): We use App Center
                        Diagnostics and App Center Diagnostics, services
                        provided by Microsoft, to
                    </p>
                    <p>
                        ● &nbsp;&nbsp; collect and analyze usage data in order
                        to understand usage patterns and improve our App.
                    </p>
                    <p>
                        ● &nbsp;&nbsp; identify crashes and errors in the App.
                    </p>
                </div>

                <p class="desc-text">
                    Your information (including unique device identifiers,
                    session durations, device models, operating systems,
                    geography, crash traces) may be stored and processed by
                    Microsoft in various locations outside Singapore, and
                    handled as described in their privacy policies at
                    https://docs.microsoft.com/en-us/appcenter/gdpr/data-from-your-end-users.
                </p>

                <p class="desc-text">
                    Google (Analytics, Cloud Messaging): We use the Google
                    Analytics for Firebase and Firebase Crashlytics, to collect
                    and analyze usage data in order to understand usage patterns
                    and to improve our iOS app. We also use Firebase Cloud
                    Messaging to enable push notification to work and to
                    diagnose issues related to push notifications. These
                    services are provided by Google Asia Pacific Pte. Ltd.
                    (“Google”). Your information (including various usage data,
                    Firebase installation IDs, Analytics App Instance IDs,
                    anonymized IP addressed, session durations, device models,
                    operating systems, geography, crash traces, devices models)
                    may be stored or processed by Google in various locations
                    outside Singapore, and handled as described in their privacy
                    policies at https://firebase.google.com/support/privacy.
                </p>

                <p class="desc-text">
                    Links to the privacy policies of third party services above
                    are provided for your convenience only, and may not be
                    current or updated. You agree to refer to the respective
                    websites of the relevant third party service provider for
                    up-to-date information on how they collect, use, disclose,
                    retain and process your Personal Data.
                </p>

                <p class="title-text" data-aos="fade-right">
                    10. NON-PERSONAL DATA
                </p>

                <div class="desc-text">
                    <p>
                        When you access and/or use the Service, we may collect,
                        use, disclose, retain and/or process (or our systems may
                        automatically collect, use, disclose, retain and/or
                        process), directly or through third party applications,
                        data that cannot be used to identify any particular
                        individual and/or data that has been anonymised so that
                        it cannot be used to identify any particular individual,
                        including the following:
                    </p>
                    <p>
                        (a) log data, including your unique device number, the
                        IP address of your computer or device, information about
                        your computer or mobile internet browser type and
                        operating system, the dates and times of your access to
                        and/or use of the Website and/or the App, the number and
                        frequency of visitors to and/or users thereof, common
                        entry and exit points into and from the Website and/or
                        the App, the number of page views or page impressions
                        that occur on the Website and/or App, the number of
                        unique visitors to and average times spent by these
                        visitors on the Website and/or App;
                    </p>
                    <p>
                        (b) session and usage data about your use of the Website
                        and/or the App, including connection and service-related
                        data such as information relating to the connection
                        request, server communication and data sharing, network
                        measurements, quality of service and date, time and
                        location of usage; and/or
                    </p>
                    <p>
                        (c) aggregate information about access to and/or use of
                        the Website and/or the App (which may contain log data
                        and session and usage data) in respect of a group or
                        category of services or users but which contains no
                        personally identifiable information about the users.
                    </p>
                    <p>(collectively “Non-Personal Data”).</p>
                </div>

                <p class="desc-text">
                    We may appoint third parties to aggregate or otherwise
                    anonymise data that is collected from you.
                </p>

                <div class="desc-text">
                    <p>
                        We may use Non-Personal Data for any purpose, including:
                    </p>
                    <p>(a) for our own internal business purposes;</p>
                    <p>
                        (b) to enable us and/or third parties appointed by us to
                        analyse, research and track access to and/or usage of
                        the Website and/or App, including to conduct internal
                        research on user demographics, interests, behaviour and
                        trends;
                    </p>
                    <p>
                        (c) to provide, improve and modify the Website and/or
                        App; and
                    </p>
                    <p>
                        (d) to disclose to third party media and/or research
                        organisations for the purpose of conducting industry
                        comparisons with other Internet portals.
                    </p>
                </div>

                <p class="title-text" data-aos="fade-right">11. COOKIES</p>

                <div class="desc-text">
                    <p>
                        We, and third party applications that we use, may
                        collect data using “cookies” when you access and/or use
                        the Website and/or App. Cookies are small data files
                        stored on your computer’s or device’s hard drive. Each
                        time you access and/or use the Website and/or App from
                        the same computer or device, the cookie will be
                        retrieved from your computer or device, enabling the
                        Website and/or App to recognise your computer or device
                        as having previously accessed and/or used the Website
                        and/or App. We use cookies to help us improve the
                        quality of access to and/or use of the Website and/or
                        App, and to provide more tailored and user-friendly
                        services to our visitors and/or users. For example, we
                        use cookies to store user preferences, track user trends
                        and obtain information about the use of the Website
                        and/or App. This information allows us to better
                        administer the Website and/or App and make the contents
                        and advertisements you see more relevant to you. Cookies
                        also help you complete tasks on the website and/or
                        mobile application without having to re-enter
                        information when browsing from one page to another or
                        when visiting and/or using the Website and/or App later.
                    </p>
                    <p>
                        Our cookies cannot be used to get data from your hard
                        drive, to get your email address or any other data that
                        can be traced to you personally.
                    </p>
                </div>

                <p class="desc-text">
                    Most web browsers and devices can be set to notify you when
                    you receive a cookie or to prevent cookies from being sent.
                    However, if you use these features, you may limit the
                    functionality we can provide you when you access and/or use
                    the Website and/or App.
                </p>
            </div>

            <div class="block-menu">
                <div class="list-menu">
                    <div class="list-menu__item hovered">
                        <p>
                            <router-link to="/privacy">Privacy</router-link>
                        </p>
                    </div>
                    <div class="list-menu__item hovered">
                        <p>
                            <router-link to="/terms">Terms</router-link>
                        </p>
                    </div>
                    <div class="list-menu__item hovered">
                        <p>
                            <router-link to="/contact">Contact</router-link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    created() {
        window.scrollTo(0, 0);
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/page/Term.scss';
</style>
